<template>
  <div class="rounded bg-light-gray text-black">
    <div class="rounded row border-2 bg-white m-0 pt-3">
      <div class="col-4">
        <h3 class="text-center text-underline">
          {{ $t("ESTIMATE_VEHICLES.ALL_VEHICLES") }} :
        </h3>
        <div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.PRICE_AVG") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $formatCurrency(cbbListing?.price_avg)
            }}</span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.KMS_AVG") }} :</span>
            <span class="font-weight-bolder ml-2"
              >{{ $numberFormat(cbbListing?.km_avg) }} km</span
            >
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.VEHICLE_COUNT") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $numberFormat(cbbListing?.total_count)
            }}</span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.DAY_AVG") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $numberFormat(cbbListing?.days_on_market)
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <h3 class="text-center text-underline">
          {{ $t("ESTIMATE_VEHICLES.ACTIVE_VEHICLES") }} :
        </h3>
        <div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.PRICE_AVG") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $formatCurrency(cbbListing?.active_price_avg)
            }}</span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.KMS_AVG") }} :</span>
            <span class="font-weight-bolder ml-2"
              >{{ $numberFormat(cbbListing?.active_km_avg) }} km</span
            >
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.VEHICLE_COUNT") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $numberFormat(cbbListing?.active_count)
            }}</span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.DAY_AVG") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $numberFormat(cbbListing?.active_days_on_market)
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-4">
        <h3 class="text-center text-underline">
          {{ $t("ESTIMATE_VEHICLES.SOLD_VEHICLES") }} :
        </h3>
        <div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.PRICE_AVG") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $formatCurrency(cbbListing?.sold_price_avg)
            }}</span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.KMS_AVG") }} :</span>
            <span class="font-weight-bolder ml-2"
              >{{ $numberFormat(cbbListing?.sold_km_avg) }} km</span
            >
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.VEHICLE_COUNT") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $numberFormat(cbbListing?.sold_count)
            }}</span>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <span>{{ $t("ESTIMATE_VEHICLES.DAY_AVG") }} :</span>
            <span class="font-weight-bolder ml-2">{{
              $numberFormat(cbbListing?.sold_days_on_market)
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="p-3 text-black clear-input-number-arrows">
      <div>
        <div class="mb-2">
          {{
            $tc("ESTIMATE_VEHICLES.AVG_DAYS_SALE", cbbListing?.days_on_market, {
              avg: cbbListing?.days_on_market,
            })
          }}
        </div>
        <div class="row my-3">
          <span
            class="col-5 d-inline-flex justify-content-start align-items-center"
          >
            {{
              $t(
                "ESTIMATE_VEHICLES.ESTIMATE_BLACK_BOOK_DRILLDOWN_RETAIL_VALUE"
              )
            }}:
          </span>
          <span
            class="col-3 d-inline-flex justify-content-start align-items-center"
          >
            ({{ $t(`ESTIMATE_VEHICLES.STATUS_${query?.condition}`) }} =
            {{ $formatCurrency(cbbDrilldown?.retail.price) }})
          </span>
          <div
            class="col-4 d-inline-flex justify-content-center align-items-center"
            v-if="!isNaN(coefficients.cbb_drilldown_retail_coefficient)"
          >
            <span class="mr-2">{{ $t("COMMON.COEFFICIENT") }}:</span>
            <base-input
              v-model.number="coefficients.cbb_drilldown_retail_coefficient"
              class="form-group-m-0"
              input-classes="border-2 text-black"
              min="0"
              rules="required"
              step="0.1"
              type="number"
              :disabled="readOnly"
            />
          </div>
        </div>
        <div class="row my-3">
          <span
            class="col-5 d-inline-flex justify-content-start align-items-center"
          >
            {{
              $t(
                "ESTIMATE_VEHICLES.ESTIMATE_BLACK_BOOK_DRILLDOWN_WHOLESALE_VALUE"
              )
            }}:
          </span>
          <span
            class="col-3 d-inline-flex justify-content-start align-items-center"
          >
            ({{ $t(`ESTIMATE_VEHICLES.STATUS_${query?.condition}`) }} =
            {{ $formatCurrency(cbbDrilldown?.whole.price) }})
          </span>
          <div
            class="col-4 d-inline-flex justify-content-center align-items-center"
            v-if="!isNaN(coefficients.cbb_drilldown_wholesale_coefficient)"
          >
            <span class="mr-2">{{ $t("COMMON.COEFFICIENT") }}:</span>

            <base-input
              v-model.number="coefficients.cbb_drilldown_wholesale_coefficient"
              class="form-group-m-0"
              input-classes="border-2 text-black"
              min="0"
              rules="required"
              step="0.1"
              type="number"
              :disabled="readOnly"
            />
          </div>
        </div>
        <div class="row my-3">
          <span
            class="col-5 d-inline-flex justify-content-start align-items-center"
          >
            {{ $t("ESTIMATE_VEHICLES.ESTIMATE_ESS_RETAIL_AVG_VALUE") }}:
          </span>
          <span
            class="col-3 d-inline-flex justify-content-start align-items-center"
          >
            ({{ $formatCurrency(ess.retail.price) }})
          </span>
          <div
            class="col-4 d-inline-flex justify-content-center align-items-center"
          >
            <!-- <span class="mr-2">Coefficient:</span>
        <input class="form-control" name="coef" rules="required" type="number" /> -->
          </div>
        </div>
        <div class="row my-3">
          <span
            class="col-5 d-inline-flex justify-content-start align-items-center"
          >
            {{ $t("ESTIMATE_VEHICLES.ESTIMATE_ESS_AVG_VALUE") }}:
          </span>
          <span
            class="col-3 d-inline-flex justify-content-start align-items-center"
          >
            ({{ $formatCurrency(ess.whole.price) }})
          </span>
          <div
            class="col-4 d-inline-flex justify-content-center align-items-center"
          >
            <!-- <span class="mr-2">Coefficient:</span>
        <input class="form-control" name="coef" rules="required" type="number" /> -->
          </div>
        </div>
      </div>
      <div class="my-3 d-flex align-items-center">
        <span style="width: min-content" class="mr-2">
          {{ $t("COMMON.OR") }}
        </span>
        <div class="d-flex flex-column flex-grow-1">
          <div class="row align-items-center my-3">
            <div
              class="col-4 d-inline-flex align-items-center justify-content-start"
            >
              <base-checkbox
                :checked="useConcessionProfit"
                :disabled="readOnly"
                @input="
                  (check) => {
                    useConcessionProfit = check;
                  }
                "
              >
                {{ $t("ESTIMATE_VEHICLES.CONCESSION_PROFIT") }}
              </base-checkbox>
            </div>
            <div class="col-8 d-flex justify-content-start">
              <base-input
                v-model.number="desiredProfit"
                class="form-group-m-0"
                append-icon="$"
                input-classes="border-0 p-2"
                input-group-classes="text-black border-2 purge-append-border"
                rules="required"
                type="number"
                :disabled="readOnly || !useConcessionProfit"
                min="0"
              >
                <template #append><span class="text-black">$</span></template>
              </base-input>
              <span class="m-2">{{ $t("COMMON.TO") }}</span>
              <base-input
                v-model.number="aggressionPercentage"
                rules="required"
                input-classes="border-0 p-2"
                input-group-classes="text-black border-2 purge-append-border"
                class="form-group-m-0"
                type="number"
                append-icon="$"
                step="0.1"
                :disabled="readOnly || !useConcessionProfit"
                min="0"
              >
                <template #append><span class="text-black">%</span></template>
              </base-input>
              <span class="m-1">({{ $t("COMMON.DETAIL") }})</span>
            </div>
          </div>
          <div class="row align-items-center my-3">
            <div class="col-4">
              <base-checkbox
                :checked="!useConcessionProfit"
                :disabled="readOnly"
                @input="
                  (check) => {
                    useConcessionProfit = !check;
                  }
                "
              >
                {{ $t("ESTIMATE_VEHICLES.WEIGHTED_PERCENTAGE") }}
              </base-checkbox>
            </div>
            <div class="col-8 d-flex align-items-center">
              <base-input
                v-model.number="weightedPercentage"
                rules="required"
                input-classes="border-0 p-2"
                input-group-classes="text-black border-2 purge-append-border"
                class="form-group-m-0"
                type="number"
                append-icon="$"
                :disabled="readOnly || useConcessionProfit"
                step="0.1"
              >
                <template #append><span class="text-black">%</span></template>
              </base-input>
              <span class="m-1">({{ $t("COMMON.WHOLESALE") }})</span>
            </div>
          </div>
        </div>
      </div>
      <div class="my-3 px-5">
        <div class="row">
          <div class="col-5 d-inline-flex align-items-center">
            <base-checkbox
              :checked="isAccidentVehicle"
              :disabled="readOnly"
              @input="
                (check) => {
                  isAccidentVehicle = check;
                  if (check) {
                    isTotalLoss = false;
                  }
                }
              "
            >
              {{ $t("ESTIMATE_VEHICLES.IS_ACCIDENT_VEHICLE") }}
            </base-checkbox>
          </div>
          <div
            class="col-7 d-inline-flex justify-content-center align-items-center"
          >
            <span class="mr-2" style="font-size: 0.875rem">{{
              $t("ESTIMATE_VEHICLES.REPAIRS_COST")
            }}</span>
            <base-input
              v-model.number="repairsCost"
              :disabled="readOnly || !isAccidentVehicle || isTotalLoss"
              class="form-group-m-0"
              append-icon="$"
              input-classes="border-0 p-2"
              input-group-classes="text-black border-2 purge-append-border"
              min="0"
              rules="required"
              type="number"
            >
              <template #append><span class="text-black">$</span></template>
            </base-input>
          </div>
          <div class="col-5 d-inline-flex align-items-center mt-">
            <base-checkbox
              :checked="isTotalLoss"
              :disabled="readOnly"
              @input="
                (check) => {
                  isTotalLoss = check;
                  if (check) {
                    isAccidentVehicle = false;
                  }
                }
              "
            >
              {{ $t("ESTIMATE_VEHICLES.IS_TOTAL_LOSS") }}
            </base-checkbox>
          </div>
        </div>
      </div>
      <div class="row my-3">
        <span
          class="col-4 d-inline-flex justify-content-start align-items-center"
          >{{ $t("ESTIMATE_VEHICLES.PRICE_VARIATION") }}</span
        >
        <div class="col-4">
          <base-input
            v-model.number="priceVariation"
            :disabled="readOnly"
            class="form-group-m-0"
            append-icon="$"
            input-classes="border-0 p-2"
            input-group-classes="text-black border-2 purge-append-border"
            min="0"
            rules="required"
            type="number"
          >
            <template #append><span class="text-black">$</span></template>
          </base-input>
        </div>
      </div>
      <div class="row align-items-start my-3">
        <div class="text-nowrap col-3">
          {{ $t("ESTIMATE_VEHICLES.PRICE_RESULT") }}
        </div>
        <div class="row col-9">
          <div
            class="col-4 d-flex flex-column align-items-center justify-content-center"
          >
            <span class="mb-1">{{ $t("COMMON.LOW") }}:</span>
            <base-input
              v-model.number="lowOfficialPrice"
              rules="required"
              input-classes="border-0 bg-gradient-darker text-white p-2"
              input-group-classes="border-2 bg-gradient-darker rounded-0 purge-append-border"
              class="form-group-m-0"
              type="number"
              append-icon="$"
              disabled
            >
              <template #append><span class="text-white">$</span></template>
            </base-input>
          </div>
          <div
            class="col-4 d-flex flex-column align-items-center justify-content-center"
          >
            <span class="mb-1"
              >{{ $t("ESTIMATE_VEHICLES.OFFICIAL_PRICE") }}:</span
            >
            <base-input
              v-model="officialPrice"
              rules="required"
              input-classes="border-0 bg-gradient-darker text-white p-2"
              input-group-classes="border-2 bg-gradient-darker rounded-0 purge-append-border"
              class="form-group-m-0"
              type="number"
              append-icon="$"
              disabled
            >
              <template #append><span class="text-white">$</span></template>
            </base-input>
          </div>
          <div
            class="col-4 d-flex flex-column align-items-center justify-content-center"
          >
            <span class="mb-1">{{ $t("COMMON.HIGH") }}:</span>
            <base-input
              v-model="highOfficialPrice"
              rules="required"
              input-classes="border-0 bg-gradient-darker text-white p-2"
              input-group-classes="border-2 bg-gradient-darker rounded-0 purge-append-border"
              class="form-group-m-0"
              type="number"
              append-icon="$"
              disabled
            >
              <template #append><span class="text-white">$</span></template>
            </base-input>
          </div>
        </div>
      </div>
      <div class="row align-items-start my-3" v-if="canShowTaxedPrice">
        <div
          class="col-3 d-flex flex-column align-items-center justify-content-around"
        >
          <span class="mb-1 flex-grow-1"
            >{{ $t("ESTIMATE_VEHICLES.ADD_TAXES") }} :</span
          >

          <div class="d-flex justify-content-center align-items-center">
            <!-- <base-checkbox
              inline-class="m-0"
              :checked="taxAdded"
              @input="
                (check) => {
                  taxAdded = check;
                }
              "
            /> -->
            <base-input
              v-model="tax"
              class="form-group-m-0"
              input-classes="border-2 text-black"
              rules="required"
              type="number"
              disabled
            >
            </base-input>
          </div>
        </div>
        <div class="row col-9">
          <div
            class="col-4 d-flex flex-column align-items-center justify-content-center"
          >
            <span class="mb-1">{{ $t("COMMON.LOW") }}:</span>
            <base-input
              v-model="lowOfficialPriceTaxed"
              rules="required"
              input-classes="border-0 bg-gradient-darker text-white p-2"
              input-group-classes="border-2 bg-gradient-darker rounded-0 purge-append-border"
              class="form-group-m-0"
              type="number"
              append-icon="$"
              disabled
            >
              <template #append><span class="text-white">$</span></template>
            </base-input>
          </div>
          <div
            class="col-4 d-flex flex-column align-items-center justify-content-center"
          >
            <span class="mb-1"
              >{{ $t("ESTIMATE_VEHICLES.OFFICIAL_PRICE") }}:</span
            >
            <base-input
              v-model="officialPriceTaxed"
              rules="required"
              input-classes="border-0 bg-gradient-darker text-white p-2"
              input-group-classes="border-2 bg-gradient-darker rounded-0 purge-append-border"
              class="form-group-m-0"
              type="number"
              append-icon="$"
              disabled
            >
              <template #append><span class="text-white">$</span></template>
            </base-input>
          </div>
          <div
            class="col-4 d-flex flex-column align-items-center justify-content-center"
          >
            <span class="mb-1">{{ $t("COMMON.HIGH") }}:</span>
            <base-input
              v-model="highOfficialPriceTaxed"
              rules="required"
              input-classes="border-0 bg-gradient-darker text-white p-2"
              input-group-classes="border-2 bg-gradient-darker rounded-0 purge-append-border"
              class="form-group-m-0"
              type="number"
              append-icon="$"
              disabled
            >
              <template #append><span class="text-white">$</span></template>
            </base-input>
          </div>
        </div>
      </div>
      <div class="my-3 mt-5">
        <p class="my-2">
          {{ $t("ESTIMATE_VEHICLES.MSRP") }} :
          <span class="font-weight-bold">
            {{ estimate.drilldown.msrp ?? "-" }}
          </span>
        </p>
        <p class="my-2">
          {{ $t("ESTIMATE_VEHICLES.WARRANTY.BASIC") }} :
          <span class="font-weight-bold">
            {{ estimate.drilldown.warranties.basic ?? "-" }}
          </span>
        </p>
        <p class="my-2">
          {{ $t("ESTIMATE_VEHICLES.WARRANTY.POWERTRAIN") }} :
          <span class="font-weight-bold">
            {{ estimate.drilldown.warranties.powertrain ?? "-" }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { ESTIMATE_VEHICLE_MEMBER_SHIP_COMPANY } from "@/constants/estimateVehicles";

export default {
  name: "estimate-summary-view",

  components: {},

  props: [
    "estimate",
    "query",
    "tax",
    "readOnly",
    "useConcessionProfitValue",
    "weightedPercentageValue",
    "taxAddedValue",
    "coefficientsValue",
    "desiredProfitValue",
    "priceVariationValue",
    "aggressionPercentageValue",
    "isAccidentVehicleValue",
    "isTotalLossValue",
    "repairsCostValue",
  ],
  data() {
    const province = this.query?.province;
    const useConcessionProfit = this.useConcessionProfitValue;
    const weightedPercentage = this.weightedPercentageValue;
    const taxAdded = this.taxAddedValue;
    const desiredProfit = this.desiredProfitValue;
    const priceVariation = this.priceVariationValue;
    const aggressionPercentage = this.aggressionPercentageValue;
    const isAccidentVehicle = this.isAccidentVehicleValue;
    const isTotalLoss = this.isTotalLossValue;
    const repairsCost = this.repairsCostValue;
    const coefficients = { ...this.coefficientsValue };
    return {
      aggressionPercentage,
      desiredProfit,
      priceVariation,
      province,
      useConcessionProfit,
      weightedPercentage,
      taxAdded,
      isAccidentVehicle,
      isTotalLoss,
      repairsCost,
      coefficients,
      ESTIMATE_VEHICLE_MEMBER_SHIP_COMPANY,
    };
  },

  computed: {
    lowOfficialPrice: function () {
      return this.estimate?.low_official_price ?? 0;
    },
    officialPrice: function () {
      return this.estimate?.official_price ?? 0;
    },
    highOfficialPrice: function () {
      return this.estimate?.high_official_price ?? 0;
    },
    lowOfficialPriceTaxed: function () {
      // if (!this.taxAdded)
      // return this.lowOfficialPrice;

      return this.estimate?.low_official_price_taxed ?? 0;
    },
    officialPriceTaxed: function () {
      // if (!this.taxAdded)
      // return this.officialPrice;

      return this.estimate?.official_price_taxed ?? 0;
    },
    highOfficialPriceTaxed: function () {
      // if (!this.taxAdded)
      // return this.highOfficialPrice;

      return this.estimate?.high_official_price_taxed ?? 0;
    },
    cbbDrilldown: function () {
      return this.estimate?.drilldown;
    },
    cbbListing: function () {
      return this.estimate?.listing;
    },
    ess: function () {
      return this.estimate?.ess;
    },
    canShowTaxedPrice: function () {
      return this.query.member_ship != ESTIMATE_VEHICLE_MEMBER_SHIP_COMPANY;
    },
  },

  watch: {
    desiredProfit() {
      this.$emit("update:desiredProfitValue", this.desiredProfit);
    },
    desiredProfitValue() {
      this.desiredProfit = this.desiredProfitValue;
    },
    priceVariation() {
      this.$emit("update:priceVariationValue", this.priceVariation);
    },
    priceVariationValue() {
      this.priceVariation = this.priceVariationValue;
    },
    aggressionPercentage() {
      this.$emit("update:aggressionPercentageValue", this.aggressionPercentage);
    },
    aggressionPercentageValue() {
      this.aggressionPercentage = this.aggressionPercentageValue;
    },
    query() {
      this.province = this.query?.province;
    },
    useConcessionProfit() {
      this.$emit("update:useConcessionProfitValue", this.useConcessionProfit);
    },
    useConcessionProfitValue(value) {
      this.useConcessionProfit = value;
    },
    weightedPercentage() {
      this.$emit("update:weightedPercentageValue", this.weightedPercentage);
    },
    weightedPercentageValue(value) {
      this.weightedPercentage = value;
    },
    taxAdded() {
      this.$emit("update:taxAddedValue", this.taxAdded);
    },
    taxAddedValue(value) {
      this.taxAdded = value;
    },
    isAccidentVehicle() {
      this.$emit("update:isAccidentVehicleValue", this.isAccidentVehicle);
    },
    isAccidentVehicleValue(value) {
      this.isAccidentVehicle = value;
    },
    isTotalLoss() {
      this.$emit("update:isTotalLossValue", this.isTotalLoss);
    },
    isTotalLossValue(value) {
      this.isTotalLoss = value;
    },
    repairsCost() {
      this.$emit("update:repairsCostValue", this.repairsCost);
    },
    repairsCostValue(value) {
      this.repairsCost = value;
    },
    coefficients: {
      handler: function () {
        this.$emit("update:coefficientsValue", this.coefficients);
      },
      deep: true,
    },
    coefficientsValue: {
      handler: function (value) {
        if (_.isEqual(value, this.coefficients)) return;

        this.coefficients = { ...this.coefficients, ...value };
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
.purge-append-border {
  .input-group-text {
    border: 0 !important;
  }
}
.border-2 {
  border: 2px solid #161616 !important;
}
.form-group-m-0 {
  .form-group {
    margin: 0 !important;
  }
}
.bg-light-gray {
  background-color: #d5d5d5 !important;
}
.text-black {
  color: #000 !important;
}
.bg-dark {
  .input-group-text {
    background-color: #212529 !important;
  }
}
.bg-gradient-darker {
  .input-group-text {
    background: linear-gradient(87deg, black 0, black 100%) !important;
  }
}
.clear-input-number-arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield !important;
  }
}
</style>
