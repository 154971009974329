export default {
  year: 0,
  make: "",
  model: "",
  kms: 0,
  kms_ratio: 75000,
  style: "",
  serie: "",
  province: "QC",
  condition: "",
  debug_mode: true,
  member_ship: "PARTICULAR",
};
