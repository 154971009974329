<template>
  <div class="tab-pane-inner">
    <h3>{{ $t("ESTIMATE_VEHICLES.ESTIMATE_BLACK_BOOK_RETAILS") }}</h3>

    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("ESTIMATE_VEHICLES.DAYS_ON_MARKET_AVG") }}
        </dt>
        <dd class="col-sm-8">{{ retails.days_on_market ?? "-" }}</dd>
      </dl>
    </div>
    <div class="row col-12 mt-md-2">
      <div class="col-lg-6 mt-md-3">
        <dl class="row">
          <dt class="col-12 mb-3 text-uppercase">
            {{ $t("ESTIMATE_VEHICLES.PRICES") }}
          </dt>
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.RETAILS.PRICE_AVG") }}
          </dt>
          <dd class="col-sm-8">${{ retails.price_avg }}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.MIN_PRICE") }}
          </dt>
          <dd class="col-sm-8">${{ retails.min_price }}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.MAX_PRICE") }}
          </dt>
          <dd class="col-sm-8">${{ retails.max_price }}</dd>
        </dl>
      </div>
      <div class="col-lg-6 mt-md-3">
        <dl class="row">
          <dt class="col-12 mb-3 text-uppercase">
            {{ $t("ESTIMATE_VEHICLES.MILEAGES") }}
          </dt>
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.RETAILS.MILEAGE_AVG") }}
          </dt>
          <dd class="col-sm-8">{{ retails.km_avg }}km</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.MIN_KMS") }}
          </dt>
          <dd class="col-sm-8">{{ retails.min_km }}km</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.MAX_KMS") }}
          </dt>
          <dd class="col-sm-8">{{ retails.max_km }}km</dd>
        </dl>
      </div>
    </div>
    <div class="row col-12 mt-md-2">
      <div class="col-lg-6 mt-md-3">
        <dl class="row">
          <dt class="col-12 mb-3 text-uppercase">
            {{ $t("ESTIMATE_VEHICLES.ACTIVES") }}
          </dt>
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.RETAILS.ACTIVE_PRICE_AVG") }}
          </dt>
          <dd class="col-sm-8">${{ retails.active_price_avg }}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.RETAILS.ACTIVE_MILEAGE_AVG") }}
          </dt>
          <dd class="col-sm-8">{{ retails.active_km_avg }}km</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.ACTIVE_DAYS_ON_MARKET_AVG") }}
          </dt>
          <dd class="col-sm-8">{{ retails.active_days_on_market ?? "-" }}</dd>
        </dl>
      </div>
      <div class="col-lg-6 mt-md-3">
        <dl class="row">
          <dt class="col-12 mb-3 text-uppercase">
            {{ $t("ESTIMATE_VEHICLES.SOLDS") }}
          </dt>
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.RETAILS.SOLD_PRICE_AVG") }}
          </dt>
          <dd class="col-sm-8">${{ retails.sold_price_avg }}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.RETAILS.SOLD_MILEAGE_AVG") }}
          </dt>
          <dd class="col-sm-8">{{ retails.sold_km_avg }}km</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("ESTIMATE_VEHICLES.SOLD_DAYS_ON_MARKET_AVG") }}
          </dt>
          <dd class="col-sm-8">{{ retails.sold_days_on_market ?? "-" }}</dd>
        </dl>
      </div>
    </div>
    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush inspections-forms"
          header-row-class-name="thead-light"
          :data="pagedTableData"
        >
          <!-- <el-table-column prop="vrank" :label="$t(`ESTIMATE_VEHICLES.VRANK`)">
            <template v-slot="{ row }">
              <span>{{ row.vrank }}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="mileage" :label="$t(`ESTIMATE_VEHICLES.KMS`)">
            <template v-slot="{ row }">
              <span>{{ row.mileage }}km</span>
            </template>
          </el-table-column>
          <el-table-column prop="price" :label="$t(`ESTIMATE_VEHICLES.PRICE`)">
            <template v-slot="{ row }">
              <span>${{ row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="series"
            :label="$t(`ESTIMATE_VEHICLES.SERIES`)"
          >
            <template v-slot="{ row }">
              <span class="text-capitalize">{{ row.series }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="style" :label="$t(`ESTIMATE_VEHICLES.STYLE`)">
            <template v-slot="{ row }">
              <span class="text-capitalize">{{ row.style }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="dealer_province"
            :label="$t(`ESTIMATE_VEHICLES.PROVINCE`)"
          >
            <template v-slot="{ row }">
              <span class="text-uppercase">{{ row.dealer_province }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="valid" :label="$t(`ESTIMATE_VEHICLES.VALID`)">
            <template v-slot="{ row }">
              <span class="text-capitalize">{{
                row.valid ? $t("COMMON.YES") : $t("COMMON.NO")
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="standardized_price"
            :label="$t(`ESTIMATE_VEHICLES.STANDARDIZED_PRICE`)"
          >
            <template v-slot="{ row }">
              <span class="text-uppercase">{{ row.standardized_price }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="standardized_mileage"
            :label="$t(`ESTIMATE_VEHICLES.STANDARDIZED_MILEAGE`)"
          >
            <template v-slot="{ row }">
              <span class="text-uppercase">{{ row.standardized_mileage }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="combined_score"
            :label="$t(`ESTIMATE_VEHICLES.COMBINED_SCORE`)"
          >
            <template v-slot="{ row }">
              <span class="text-uppercase">{{ row.combined_score }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="weighted_percentage"
            :label="$t(`ESTIMATE_VEHICLES.WEIGHTED_PERCENTAGE`)"
          >
            <template v-slot="{ row }">
              <span class="text-uppercase">{{ row.weighted_percentage }}</span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <div class="list-table-inner-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="pagedTableData.length">
            &nbsp; &nbsp;
            {{
              $t("COMMON.X_LINES_SELECTED", { count: pagedTableData.length })
            }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="currentPage"
          :per-page="perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";

export default {
  name: "retails-view",

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  props: ["retails"],

  data() {
    return {
      currentPage: 1,
      perPage: 7,
    };
  },

  computed: {
    total() {
      return this.retails.data.length;
    },
    pagedTableData() {
      return this.retails.data.slice(
        this.perPage * this.currentPage - this.perPage,
        this.perPage * this.currentPage
      );
    },
    from() {
      return this.perPage * (this.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  methods: {},

  mounted() {},
};
</script>
