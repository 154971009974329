<template>
  <div class="tab-pane-inner">
    <h3>{{ $t("ESTIMATE_VEHICLES.ESTIMATE_BLACK_BOOK_DRILLDOWN") }}</h3>
    <div class="list-table-inner-body">
      <div class="list-table-inner-body-inner">
        <el-table
          class="align-items-center table-flush inspections-forms"
          header-row-class-name="thead-light"
          :data="estimate"
        >
          <el-table-column prop="category" label="">
            <template v-slot="{ row }">
              <span>{{ $t(`ESTIMATE_VEHICLES.${row.category}`) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" :label="$t(`ESTIMATE_VEHICLES.STATUS`)">
            <template v-slot="{ row }">
              <span>{{ $t(`ESTIMATE_VEHICLES.STATUS_${row.type}`) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="base"
            :label="$t(`ESTIMATE_VEHICLES.BASE_PRICE`)"
          >
          </el-table-column>
          <el-table-column
            prop="mileage"
            :label="$t(`ESTIMATE_VEHICLES.MILEAGE_PRICE`)"
          >
          </el-table-column>
          <el-table-column
            prop="regional"
            :label="$t(`ESTIMATE_VEHICLES.REGIONAL_PRICE`)"
          >
          </el-table-column>
          <el-table-column
            prop="adjusted"
            :label="$t(`ESTIMATE_VEHICLES.ADJUSTED_PRICE`)"
          >
          </el-table-column>
          <el-table-column prop="km" :label="$t(`ESTIMATE_VEHICLES.KMS`)">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";

export default {
  name: "drilldown-view",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  props: ["drilldownEstimate"],

  data() {
    return {};
  },

  computed: {
    estimate: function () {
      const data = { ...this.drilldownEstimate };
      const transformedData = [];

      for (const category in data) {
        if (["retail", "whole"].includes(category)) {
          for (const type in data[category]) {
            const item = {
              category: category.toUpperCase(),
              type: type.toUpperCase(),
              base: data[category][type].base,
              mileage: data[category][type].mileage,
              regional: data[category][type].regional,
              adjusted: data[category][type].adjusted,
              km: data[category][type].adjusted_km,
            };
            transformedData.push(item);
          }
        }
      }

      return transformedData;
    },
  },

  methods: {},

  mounted() {},
};
</script>
