<template>
  <div class="row align-items-stretch">
    <div :class="formClasses">
      <card>
        <estimate-form
          :formErrors="formErrors"
          :estimate-data="estimateData"
          :loading="loading"
          :read-only="readOnly"
          @estimateSubmitted="handleSubmit"
          @onResetForm="resetForm"
        />
      </card>
    </div>
    <div class="col-lg-7" v-if="estimate?.data">
      <card>
        <estimate-summary-view
          :aggression-percentage-value.sync="aggressionPercentage"
          :coefficients-value.sync="coefficients"
          :debug-mode="canShowDebug"
          :desired-profit-value.sync="desiredProfit"
          :estimate="calculationData"
          :is-accident-vehicle-value.sync="isAccidentVehicle"
          :is-total-loss-value.sync="isTotalLoss"
          :price-variation-value.sync="priceVariation"
          :query="estimate.query"
          :read-only="readOnly"
          :repairs-cost-value.sync="repairsCost"
          :tax-added-value.sync="taxAdded"
          :tax="estimate?.parameters?.tax"
          :use-concession-profit-value.sync="useConcessionProfit"
          :weighted-percentage-value.sync="weightedPercentage"
        />
      </card>
    </div>
    <div class="col-md-12" v-if="canShowDebug && debug">
      <card style="flex: 1">
        <span class="resize-loading" v-if="loading">
          <span class="loader"></span>
        </span>
        <estimate-view
          :estimate="calculationData"
          :coefficients-value.sync="coefficients"
          :debug="debug"
          :km="estimate?.parameters?.kms"
          :loading="loading"
          :desired-profit-value.sync="desiredProfit"
          :aggression-percentage-value.sync="aggressionPercentage"
          :price-variation-value.sync="priceVariation"
          :read-only="readOnly"
        />
      </card>
    </div>
    <div class="col-md-6 d-flex" v-if="canShowDebug && debug?.ess.data">
      <card style="flex: 1">
        <span class="resize-loading" v-if="loading">
          <span class="loader"></span>
        </span>
        <ess-estimate-view :estimate="debug?.ess.data" :loading="loading" />
      </card>
    </div>
    <div class="col-md-6" v-if="canShowDebug && debug?.drilldown?.data">
      <card style="flex: 1">
        <span class="resize-loading" v-if="loading">
          <span class="loader"></span>
        </span>
        <drilldown-view :drilldown-estimate="debug?.drilldown?.data ?? []" />
      </card>
    </div>
    <div class="col-md-12" v-if="canShowDebug && debug.listing">
      <card style="flex: 1">
        <span class="resize-loading" v-if="loading">
          <span class="loader"></span>
        </span>
        <retails-view :retails="debug.listing" />
      </card>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Cards/Card.vue";
import EstimateForm from "../partials/EstimateForm.vue";
import EssEstimateView from "../partials/EssEstimateView.vue";
import EstimateView from "../partials/EstimateView.vue";
import EstimateSummaryView from "../partials/EstimateSummaryView.vue";
import DrilldownView from "../partials/BlackBook/DrilldownView.vue";
import RetailsView from "../partials/BlackBook/RetailsView.vue";
import defaultVehicleEstimate from "../defaultVehicleEstimate";
import _, { cloneDeep } from "lodash";
import requestErrorMixin from "@/mixins/request-error-mixin";
import swal from "sweetalert2";

export default {
  layout: "DashboardLayout",

  components: {
    Card,
    EstimateForm,
    EssEstimateView,
    DrilldownView,
    RetailsView,
    EstimateView,
    EstimateSummaryView,
  },

  mixins: [requestErrorMixin],

  props: {
    requestLog: {
      type: Object,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    formClasses: {
      type: String,
      default: "col-lg-5",
    },
  },

  data() {
    let estimateData = { ...defaultVehicleEstimate };
    let estimate = null;
    if (this.requestLog) {
      const parameters = this.requestLog.parameters;
      estimateData = {
        ...estimateData,
        ...parameters,
        serie: parameters?.series,
      };
      estimate = { ...this.requestLog.result };
    }
    return {
      estimate,
      estimateData,
      loading: false,
      formErrors: null,
      useConcessionProfit: true,
      weightedPercentage: 86.7,
      taxAdded: true,
      priceVariation: 1000,
      aggressionPercentage: 95,
      desiredProfit: 3000,
      km: 0,
      isAccidentVehicle: false,
      isTotalLoss: false,
      repairsCost: 0,
      coefficients: {
        ess_wholesale_coefficient: 0.6,
        ess_retail_coefficient: 0.6,
        cbb_drilldown_wholesale_coefficient: 0.8,
        cbb_drilldown_retail_coefficient: 0.8,
        cbb_listing_coefficient: 0.8,
      },
    };
  },

  computed: {
    canShowDebug: function () {
      return (
        this.$currentUserCan(
          this.$permissions.PERM_VIEW_VEHICLE_ESTIMATIONS_DEBUG_MODE
        ) && this.estimate?.parameters?.show_debug_mode
      );
    },

    debug: function () {
      return this.estimate?.debug;
    },

    calculationData: function () {
      return this.estimate?.data;
    },

    formData: function () {
      let form = {
        ...this.estimate?.query,
        use_concession_profit: this.useConcessionProfit,
        price_variation: this.priceVariation,
        aggression_percentage: this.aggressionPercentage,
        is_accident_vehicle: this.isAccidentVehicle,
        is_total_loss: this.isTotalLoss,
        repairs_cost: this.repairsCost,
        ...this.coefficients,
      };
      if (this.useConcessionProfit) {
        form = {
          ...form,
          desired_profit: this.desiredProfit,
        };
      } else {
        form = {
          ...form,
          weighted_percentage: this.weightedPercentage,
        };
      }

      return form;
    },
  },

  watch: {
    requestLog: {
      immediate: true,
      handler(requestLog) {
        if (requestLog) {
          const parameters = requestLog.parameters;
          this.estimateData = {
            ...this.estimateData,
            ...parameters,
            serie: parameters?.series,
          };
          this.estimate = { ...requestLog.result };
        }
      },
    },
    estimate() {
      if (this.estimate) {
        this.km = this.estimate?.parameters?.kms;
        this.aggressionPercentage =
          this.estimate?.parameters?.aggression_percentage;
        this.useConcessionProfit =
          this.estimate?.parameters?.use_concession_profit;
        this.weightedPercentage =
          this.estimate?.parameters?.weighted_percentage;
        this.desiredProfit = this.estimate?.parameters?.desired_profit;
        this.isAccidentVehicle = this.estimate?.parameters?.is_accident_vehicle;
        this.isTotalLoss = this.estimate?.parameters?.is_total_loss;
        this.repairsCost = this.estimate?.parameters?.repairs_cost;
      }
    },
    priceVariation(value, oldValue) {
      if (value != oldValue) {
        this.sendRequest();
      }
    },
    aggressionPercentage(value, oldValue) {
      if (value != oldValue && this.useConcessionProfit) {
        this.sendRequest();
      }
    },
    desiredProfit(value, oldValue) {
      if (value != oldValue && this.useConcessionProfit) {
        this.sendRequest();
      }
    },
    useConcessionProfit(value, oldValue) {
      if (value != oldValue) {
        this.sendRequest();
      }
    },
    weightedPercentage(value, oldValue) {
      if (value != oldValue && !this.useConcessionProfit) {
        this.sendRequest();
      }
    },
    isAccidentVehicle(value, oldValue) {
      if (value != oldValue) {
        this.sendRequest();
      }
    },
    isTotalLoss(value, oldValue) {
      if (value != oldValue) {
        this.sendRequest();
      }
    },
    repairsCost(value, oldValue) {
      if (value != oldValue && this.isAccidentVehicle && !this.isTotalLoss) {
        this.sendRequest();
      }
    },
    coefficients: {
      handler: function (value, oldValue) {
        this.sendRequest();
      },
      deep: true,
    },
  },

  methods: {
    resetForm() {
      this.estimateData = { ...defaultVehicleEstimate };
      this.estimate = null;
    },
    sendRequest: _.debounce(function () {
      if (this.readOnly) return;

      this.getEstimate(this.formData);
    }, 800),
    async getEstimate(estimate) {
      this.loading = true;
      this.formErrors = null;
      const estimateData = { ...this.formData, ...cloneDeep(estimate) };
      try {
        await this.$store.dispatch("vehiclesEstimate/get", estimateData);
        this.estimate = await this.$store.getters["vehiclesEstimate/estimate"];

        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("COMMON.RESPONSE_READY"),
        });
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error?.response?.data?.errors;
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(estimate) {
      if (this.readOnly) return;

      this.estimate = null;

      estimate.is_accident_vehicle = false;
      estimate.is_total_loss = false;
      estimate.repairs_cost = 0;

      this.getEstimate(estimate);
    },
  },
};
</script>
