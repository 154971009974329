<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("ESTIMATE_VEHICLES.ESTIMATE_ESS") }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-4">{{ $t("ESTIMATE_VEHICLES.MAKE") }} (make)</dt>
        <dd class="col-sm-8">
          {{ estimate?.make ?? "-" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("ESTIMATE_VEHICLES.MODEL_YEAR") }} (year)
        </dt>
        <dd class="col-sm-8">
          {{ estimate?.year ?? "-" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">{{ $t("ESTIMATE_VEHICLES.MODEL") }} (model)</dt>
        <dd class="col-sm-8">
          {{ estimate?.model ?? "-" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("ESTIMATE_VEHICLES.KMS_MIN") }} (kms_min)
        </dt>
        <dd class="col-sm-8">
          {{ estimate?.kms_min ? `${estimate?.kms_min} km` : "-" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("ESTIMATE_VEHICLES.KMS_MAX") }} (kms_max)
        </dt>
        <dd class="col-sm-8">
          {{ estimate?.kms_max ? `${estimate?.kms_max} km` : "-" }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("ESTIMATE_VEHICLES.ESTIMATE_SOLD") }} (evaluations.sold)
        </dt>
        <dd class="col-sm-8">
          {{
            estimate?.evaluations?.sold
              ? `$${estimate?.evaluations?.sold}`
              : "-"
          }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("ESTIMATE_VEHICLES.ESTIMATE_SOLD_ACTIVE") }}
          (evaluations.active)
        </dt>
        <dd class="col-sm-8">
          {{
            estimate?.evaluations?.active
              ? `$${estimate?.evaluations?.active}`
              : "-"
          }}
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-4">
          {{ $t("ESTIMATE_VEHICLES.ESTIMATE_SOLD_ESS") }} (sales.sold)
        </dt>
        <dd class="col-sm-8">
          {{ estimate?.sales?.sold ? `$${estimate?.sales?.sold}` : "-" }}
        </dd>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  name: "ess-estimate-view",

  components: {},

  props: ["estimate"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
