<template>
  <el-select
    v-model="provinceModel"
    @change="provinceChanged"
    :placeholder="$t('ESTIMATE_VEHICLES.PROVINCE')"
    :filterable="filterable"
    :disabled="disabled"
  >
    <el-option :value="null" :label="$t('ESTIMATE_VEHICLES.PROVINCE')">
    </el-option>
    <el-option
      v-for="province in provinces"
      :key="province.value"
      :value="province.value"
      :label="province.label"
    >
    </el-option>
  </el-select>
</template>

<script>
import _ from "lodash";
import { Option, Select } from "element-ui";
import { provinceNames } from "@/constants/provinces";

export default {
  name: "province-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },
    value: {
      type: String | Number | null | undefined,
      default: "",
      description: "Value",
    },
  },

  data() {
    const provinceModel = this.value;
    return {
      provinceModel,
      provinceNames,
    };
  },

  setup() {},

  created() {},

  computed: {
    provinces: function () {
      return Object.keys(this.provinceNames).map((value) => ({
        value,
        label: this.$t(`PROVINCES.${value}`),
      }));
    },
  },

  methods: {
    provinceChanged(province) {
      this.$emit("provinceChanged", province);
    },
  },

  watch: {
    value() {
      this.provinceModel = this.value;
    },
  },
};
</script>
