export const PROVINCE_AB = "AB";
export const PROVINCE_BC = "BC";
export const PROVINCE_CN = "CN";
export const PROVINCE_MB = "MB";
export const PROVINCE_NB = "NB";
export const PROVINCE_NL = "NL";
export const PROVINCE_NS = "NS";
export const PROVINCE_NT = "NT";
export const PROVINCE_NU = "NU";
export const PROVINCE_ON = "ON";
export const PROVINCE_PE = "PE";
export const PROVINCE_QC = "QC";
export const PROVINCE_SK = "SK";
export const PROVINCE_YT = "YT";

export const provinceNames = {
  [PROVINCE_AB]: "Alberta",
  [PROVINCE_BC]: "British Columbia",
  [PROVINCE_CN]: "National",
  [PROVINCE_MB]: "Manitoba",
  [PROVINCE_NB]: "New Brunswick",
  [PROVINCE_NL]: "Newfoundland",
  [PROVINCE_NS]: "Nova Scotia",
  [PROVINCE_NT]: "Northwest Territory",
  [PROVINCE_NU]: "Nunavut",
  [PROVINCE_ON]: "Ontario",
  [PROVINCE_PE]: "Prince Edward Island",
  [PROVINCE_QC]: "Quebec",
  [PROVINCE_SK]: "Saskatchewan",
  [PROVINCE_YT]: "Yukon Territory",
};

export const provinceTaxes = {
  [PROVINCE_AB]: 0.05,
  [PROVINCE_BC]: 0.12,
  [PROVINCE_CN]: 0.05,
  [PROVINCE_MB]: 0.12,
  [PROVINCE_NB]: 0.15,
  [PROVINCE_NL]: 0.15,
  [PROVINCE_NS]: 0.15,
  [PROVINCE_NT]: 0.05,
  [PROVINCE_NU]: 0.05,
  [PROVINCE_ON]: 0.13,
  [PROVINCE_PE]: 0.15,
  [PROVINCE_QC]: 0.14975,
  [PROVINCE_SK]: 0.11,
  [PROVINCE_YT]: 0.05,
};

export const provinceAdjustments = {
  [PROVINCE_AB]: { car_adj_perc: 1.07, truck_adj_perc: 1.06 },
  [PROVINCE_BC]: { car_adj_perc: 1.07, truck_adj_perc: 1.06 },
  [PROVINCE_CN]: { car_adj_perc: 1, truck_adj_perc: 1 },
  [PROVINCE_MB]: { car_adj_perc: 1.04, truck_adj_perc: 1.04 },
  [PROVINCE_NB]: { car_adj_perc: 0.97, truck_adj_perc: 0.98 },
  [PROVINCE_NL]: { car_adj_perc: 0.97, truck_adj_perc: 0.98 },
  [PROVINCE_NS]: { car_adj_perc: 0.97, truck_adj_perc: 0.98 },
  [PROVINCE_NT]: { car_adj_perc: 1.07, truck_adj_perc: 1.06 },
  [PROVINCE_NU]: { car_adj_perc: 1.07, truck_adj_perc: 1.06 },
  [PROVINCE_ON]: { car_adj_perc: 1, truck_adj_perc: 1 },
  [PROVINCE_PE]: { car_adj_perc: 0.97, truck_adj_perc: 0.98 },
  [PROVINCE_QC]: { car_adj_perc: 0.93, truck_adj_perc: 0.95 },
  [PROVINCE_SK]: { car_adj_perc: 1.04, truck_adj_perc: 1.04 },
  [PROVINCE_YT]: { car_adj_perc: 1.07, truck_adj_perc: 1.06 },
};
