<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("ESTIMATE_VEHICLES.ESTIMATES") }}</h3>
    <div class="col-12">
      <dl class="row">
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.ESS_OBJECT.WHOLE") }}</dt>
        <dd class="col-sm-6">{{ `$${debug.ess.whole.price}` ?? "-" }}</dd>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.KMS") }}</dt>
        <dd class="col-sm-6">{{ `${debug.ess.whole.km}km` ?? "-" }}</dd>
        <div class="col-md-6">
          <base-input
            v-model.number="coefficients.ess_wholesale_coefficient"
            :placeholder="$t('ESTIMATE_VEHICLES.PERCENTS.ESS.WHOLE')"
            min="0"
            rules="required|numeric|min:0"
            step="0.1"
            type="number"
            :disabled="readOnly"
          />
        </div>
      </dl>
      <dl class="row">
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.ESS_OBJECT.RETAIL") }}
        </dt>
        <dd class="col-sm-6">{{ `$${debug.ess.retail.km}` ?? "-" }}</dd>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.KMS") }}</dt>
        <dd class="col-sm-6">{{ `${debug.ess.retail.km}km` ?? "-" }}</dd>
        <div class="col-md-6">
          <base-input
            v-model.number="coefficients.ess_retail_coefficient"
            :placeholder="$t('ESTIMATE_VEHICLES.PERCENTS.ESS.RETAIL')"
            min="0"
            rules="required|numeric|min:0"
            step="0.1"
            type="number"
            :disabled="readOnly"
          />
        </div>
      </dl>
      <dl class="row">
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.BLACK_BOOK.DRILLDOWN.WHOLE") }}
        </dt>
        <dd class="col-sm-6">{{ `$${debug.drilldown.whole.price}` ?? "-" }}</dd>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.KMS") }}</dt>
        <dd class="col-sm-6">{{ `${debug.drilldown.whole.km}km` ?? "-" }}</dd>
        <div class="col-md-6">
          <base-input
            v-model.number="coefficients.cbb_drilldown_wholesale_coefficient"
            :placeholder="$t('ESTIMATE_VEHICLES.PERCENTS.DRILLDOWN.WHOLE')"
            min="0"
            rules="required|numeric|min:0"
            step="0.1"
            type="number"
            :disabled="readOnly"
          />
        </div>
      </dl>
      <dl class="row">
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.BLACK_BOOK.DRILLDOWN.RETAIL") }}
        </dt>
        <dd class="col-sm-6">
          {{ `$${debug.drilldown.retail.price}` ?? "-" }}
        </dd>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.KMS") }}</dt>
        <dd class="col-sm-6">{{ `${debug.drilldown.retail.km}km` ?? "-" }}</dd>
        <div class="col-md-6">
          <base-input
            v-model.number="coefficients.cbb_drilldown_retail_coefficient"
            :placeholder="$t('ESTIMATE_VEHICLES.PERCENTS.DRILLDOWN.RETAIL')"
            min="0"
            rules="required|numeric|min:0"
            step="0.1"
            type="number"
            :disabled="readOnly"
          />
        </div>
      </dl>
      <dl class="row">
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.BLACK_BOOK.RETAILS") }}
        </dt>
        <dd class="col-sm-6">{{ `$${debug.listing.price_avg}` ?? "-" }}</dd>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.KMS") }}</dt>
        <dd class="col-sm-6">{{ `${debug.listing.km_avg}km` ?? "-" }}</dd>
        <div class="col-md-6">
          <base-input
            v-model.number="coefficients.cbb_listing_coefficient"
            :placeholder="$t('ESTIMATE_VEHICLES.PERCENTS.RETAILS')"
            min="0"
            rules="required|numeric|min:0"
            step="0.1"
            type="number"
            :disabled="readOnly"
          />
        </div>
      </dl>
      <dl class="row">
        <dt class="col-12">
          {{ $t("ESTIMATE_VEHICLES.AVG.WHOLE") }}({{
            debug.whole.weighted_percentage
          }}%)
        </dt>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.PRICE") }}</dt>
        <dd class="col-sm-6">{{ `$${debug.whole.price}` ?? "-" }}</dd>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.KMS") }}</dt>
        <dd class="col-sm-6">{{ `${debug.whole.km}km` ?? "-" }}</dd>
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.WEIGHTED_PERCENTAGE") }}
        </dt>
        <dd class="col-sm-6">{{ debug.whole.weighted_percentage }}%</dd>
      </dl>
      <dl class="row">
        <dt class="col-12">
          {{ $t("ESTIMATE_VEHICLES.AVG.RETAIL") }}({{
            debug.retail.weighted_percentage
          }}%)
        </dt>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.PRICE") }}</dt>
        <dd class="col-sm-6">{{ `$${debug.retail.price}` ?? "-" }}</dd>
        <dt class="col-sm-6">{{ $t("ESTIMATE_VEHICLES.KMS") }}</dt>
        <dd class="col-sm-6">{{ `${debug.retail.km}km` ?? "-" }}</dd>
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.WEIGHTED_PERCENTAGE") }}
        </dt>
        <dd class="col-sm-6">{{ debug.retail.weighted_percentage }}%</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-6 col-md-4">
          {{ $t("ESTIMATE_VEHICLES.AGGRESSION_PERCENTAGE") }}
        </dt>
        <dd class="col-sm-6 col-md-4">{{ aggressionPercentage }}%</dd>
        <div class="col-sm-6 col-md-4">
          <base-input
            :placeholder="$t('ESTIMATE_VEHICLES.AGGRESSION_PERCENTAGE')"
            v-model.number="aggressionPercentage"
            :disabled="readOnly"
            rules="required|numeric|min:0"
            type="number"
            min="0"
            step="0.1"
          />
        </div>

        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.VEHICLE_KMS") }}
        </dt>
        <dd class="col-sm-6">
          <base-input
            :placeholder="$t('ESTIMATE_VEHICLES.VEHICLE_KMS')"
            v-model.number="km"
            rules="required|numeric|min:0"
            type="number"
            min="0"
            disabled
          />
        </dd>
        <!-- <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.CONCESSION_PROFIT") }}({{ aggressionPercentage }}%)
        </dt>
        <dd class="col-sm-6">${{ getPrice }}</dd> -->
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.DESIRED_PROFIT") }}
        </dt>
        <dd class="col-sm-6">
          <base-input
            :placeholder="$t('ESTIMATE_VEHICLES.DESIRED_PROFIT')"
            v-model.number="desiredProfit"
            :disabled="readOnly"
            rules="required|numeric|min:0"
            type="number"
            min="0"
          />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.PRICE_VARIATION") }}
        </dt>
        <dd class="col-sm-6">
          <base-input
            :placeholder="$t('ESTIMATE_VEHICLES.PRICE_VARIATION')"
            v-model.number="priceVariation"
            rules="required|numeric|min:0"
            :disabled="readOnly"
            type="number"
            min="0"
          />
        </dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.OFFICIAL_PRICE") }}
        </dt>
        <dd class="col-sm-6">${{ officialPrice }}</dd>
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.LOW_OFFICIAL_PRICE") }}
        </dt>
        <dd class="col-sm-6">${{ lowOfficialPrice }}</dd>
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.HIGH_OFFICIAL_PRICE") }}
        </dt>
        <dd class="col-sm-6">${{ highOfficialPrice }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.OFFICIAL_TAXED_PRICE") }}
        </dt>
        <dd class="col-sm-6">${{ officialPriceTaxed }}</dd>
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.LOW_OFFICIAL_TAXED_PRICE") }}
        </dt>
        <dd class="col-sm-6">${{ lowOfficialPriceTaxed }}</dd>
        <dt class="col-sm-6">
          {{ $t("ESTIMATE_VEHICLES.HIGH_OFFICIAL_TAXED_PRICE") }}
        </dt>
        <dd class="col-sm-6">${{ highOfficialPriceTaxed }}</dd>
      </dl>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import _ from "lodash";

export default {
  name: "estimate-view",

  components: {},

  props: [
    "estimate",
    "desiredProfitValue",
    "priceVariationValue",
    "aggressionPercentageValue",
    "coefficientsValue",
    "km",
    "readOnly",
    "debug",
  ],

  data() {
    const desiredProfit = this.desiredProfitValue;
    const priceVariation = this.priceVariationValue;
    const aggressionPercentage = this.aggressionPercentageValue;
    const coefficients = { ...this.coefficientsValue };
    return {
      aggressionPercentage,
      desiredProfit,
      priceVariation,
      coefficients,
    };
  },

  computed: {
    calculationData: function () {
      return {
        aggressionPercentage: this.aggressionPercentage,
        desiredProfit: this.desiredProfit,
        km: this.km,
        priceVariation: this.priceVariation,
        tax: this.tax,
      };
    },
    lowOfficialPrice: function () {
      return this.debug?.official_prices?.low_official_price ?? 0;
    },
    officialPrice: function () {
      return this.debug?.official_prices?.official_price ?? 0;
    },
    highOfficialPrice: function () {
      return this.debug?.official_prices?.high_official_price ?? 0;
    },
    lowOfficialPriceTaxed: function () {
      return this.debug?.official_prices?.low_official_price_taxed ?? 0;
    },
    officialPriceTaxed: function () {
      return this.debug?.official_prices?.official_price_taxed ?? 0;
    },
    highOfficialPriceTaxed: function () {
      return this.debug?.official_prices?.high_official_price_taxed ?? 0;
    },
    wholeWeightedPercentage: function () {
      return 0;
    },
    whole: function () {
      return this.estimate?.wholes_avg;
    },
    retail: function () {
      return this.estimate?.retails_avg;
    },
  },

  created() {
    extend("numeric", {
      validate: (value) => !isNaN(value),
      message: (_) => this.$t("validations.messages.numeric"),
    });
  },

  methods: {},

  mounted() {},

  watch: {
    desiredProfit() {
      this.$emit("update:desiredProfitValue", this.desiredProfit);
    },
    desiredProfitValue() {
      this.desiredProfit = this.desiredProfitValue;
    },
    priceVariation() {
      this.$emit("update:priceVariationValue", this.priceVariation);
    },
    priceVariationValue() {
      this.priceVariation = this.priceVariationValue;
    },
    aggressionPercentage() {
      this.$emit("update:aggressionPercentageValue", this.aggressionPercentage);
    },
    aggressionPercentageValue() {
      this.aggressionPercentage = this.aggressionPercentageValue;
    },
    coefficients: {
      handler: function () {
        this.$emit("update:coefficientsValue", this.coefficients);
      },
      deep: true,
    },
    coefficientsValue: {
      handler: function (value) {
        if (_.isEqual(value, this.coefficients)) return;

        this.coefficients = { ...this.coefficients, ...value };
      },
      deep: true,
    },
  },
};
</script>
