export const ESTIMATE_VEHICLE_STATE_ROUGH = "ROUGH";
export const ESTIMATE_VEHICLE_STATE_XCLEAN = "XCLEAN";
export const ESTIMATE_VEHICLE_STATE_CLEAN = "CLEAN";
export const ESTIMATE_VEHICLE_STATE_AVG = "AVG";

export const estimateVehicleConditionsStatesOption = [
  ESTIMATE_VEHICLE_STATE_XCLEAN,
  ESTIMATE_VEHICLE_STATE_CLEAN,
  ESTIMATE_VEHICLE_STATE_AVG,
  ESTIMATE_VEHICLE_STATE_ROUGH,
];

export const ESTIMATE_VEHICLE_MEMBER_SHIP_PARTICULAR = "PARTICULAR";
export const ESTIMATE_VEHICLE_MEMBER_SHIP_COMPANY = "COMPANY";

export const estimateVehicleMemberShipOption = [
  ESTIMATE_VEHICLE_MEMBER_SHIP_PARTICULAR,
  ESTIMATE_VEHICLE_MEMBER_SHIP_COMPANY,
];

export const estimateVehicleRequestLogsStatusCodeOptions = [200, 422, 429, 500];
