<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <form
      class="advanced-search"
      ref="estimate_form"
      @submit.prevent="handleSubmit(hdlSubmit)"
    >
      <div class="row">
        <div class="col-md-12">
          <base-input
            :disabled="readOnly"
            :label="`${$t('ESTIMATE_VEHICLES.MODEL_YEAR')} (*)`"
            :placeholder="$t('ESTIMATE_VEHICLES.MODEL_YEAR')"
            label-classes="form-control-label mt-0"
            name="year"
          >
            <year-selector
              :disabled="readOnly"
              :filterable="true"
              :value="estimate.year"
              @yearChanged="
                (year) => {
                  estimate.year = year;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.year" />
        </div>
        <div class="col-md-6">
          <base-input
            label-classes="form-control-label mt-0"
            :label="`${$t('ESTIMATE_VEHICLES.MAKE')} (*)`"
            :placeholder="$t('ESTIMATE_VEHICLES.MAKE')"
            name="make"
            :disabled="readOnly"
          >
            <make-selector
              :year="estimate.year"
              :disabled="readOnly"
              :filterable="true"
              :value="estimate.make"
              @makeChanged="
                (make) => {
                  estimate.make = make;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.make" />
        </div>
        <div class="col-md-6">
          <base-input
            label-classes="form-control-label mt-0"
            :label="`${$t('ESTIMATE_VEHICLES.MODEL')} (*)`"
            :placeholder="$t('ESTIMATE_VEHICLES.MODEL')"
            name="model"
            :disabled="readOnly"
          >
            <model-selector
              :year="estimate.year"
              :disabled="readOnly"
              :filterable="true"
              :make="estimate.make"
              :value="estimate.model"
              @modelChanged="
                (model) => {
                  estimate.model = model;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.model" />
        </div>
        <div class="col-md-6">
          <base-input
            label-classes="form-control-label mt-0"
            :label="`${$t('ESTIMATE_VEHICLES.SERIES')} (*)`"
            :placeholder="$t('ESTIMATE_VEHICLES.SERIES')"
            :disabled="readOnly"
          >
            <serie-selector
              :year="estimate.year"
              :make="estimate.make"
              :disabled="readOnly"
              :model="estimate.model"
              :filterable="true"
              :value="estimate.serie"
              @serieChanged="
                (serie) => {
                  estimate.serie = serie;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.serie" />
        </div>
        <div class="col-md-6">
          <base-input
            label-classes="form-control-label mt-0"
            :label="`${$t('ESTIMATE_VEHICLES.STYLE')} (*)`"
            :placeholder="$t('ESTIMATE_VEHICLES.STYLE')"
            v-model="estimate.style"
            :disabled="readOnly"
          >
            <style-selector
              :year="estimate.year"
              :make="estimate.make"
              :model="estimate.model"
              :serie="estimate.serie"
              :disabled="readOnly"
              :filterable="true"
              :value="estimate.style"
              @styleChanged="
                (style) => {
                  estimate.style = style;
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.style" />
        </div>
        <div class="col-md-6">
          <base-input
            v-model.number="estimate.kms"
            :disabled="readOnly"
            :label="`${$t('ESTIMATE_VEHICLES.KMS')} (*)`"
            :placeholder="$t('ESTIMATE_VEHICLES.KMS')"
            label-classes="form-control-label mt-0"
            min="0"
            name="kms"
            rules="required|numeric"
            type="number"
          />
          <validation-error :errors="apiValidationErrors.kms" />
        </div>
        <div class="col-md-6">
          <base-input
            v-model.number="estimate.kms_ratio"
            :disabled="readOnly"
            :label="`${$t('ESTIMATE_VEHICLES.KMS_RATIO')}`"
            :placeholder="$t('ESTIMATE_VEHICLES.KMS_RATIO')"
            label-classes="form-control-label mt-0"
            min="0"
            rules="numeric"
            type="number"
          />
          <validation-error :errors="apiValidationErrors.kms_ratio" />
        </div>
        <div class="col-md-6">
          <base-input
            label-classes="form-control-label mt-0"
            :label="`${$t('ESTIMATE_VEHICLES.STATUS')}`"
            :placeholder="$t('ESTIMATE_VEHICLES.STATUS')"
            vid="component_condition"
            :disabled="readOnly"
          >
            <el-select
              v-model="estimate.condition"
              :disabled="readOnly"
              :placeholder="
                estimate.condition ? '' : $t('ESTIMATE_VEHICLES.STATUS')
              "
            >
              <el-option
                v-for="condition in statesOptions"
                class="custom-select-option"
                :value="condition"
                :label="$t(`ESTIMATE_VEHICLES.STATUS_${condition}`)"
                :key="condition"
              >
                <div class="option-inner">
                  <span class="text">{{
                    $t(`ESTIMATE_VEHICLES.STATUS_${condition}`)
                  }}</span>
                </div>
              </el-option>
            </el-select>
          </base-input>
          <validation-error :errors="apiValidationErrors.condition" />
        </div>
        <div class="col-md-6">
          <base-input
            label-classes="form-control-label mt-0"
            :label="$t('ESTIMATE_VEHICLES.PROVINCE')"
            :placeholder="$t('ESTIMATE_VEHICLES.PROVINCE')"
            v-model="estimate.province"
            :disabled="readOnly"
          >
            <province-selector
              :disabled="readOnly"
              :filterable="true"
              :value="estimate.province"
              @provinceChanged="
                (province) => {
                  estimate.province = province;
                }
              "
            />
          </base-input>

          <validation-error :errors="apiValidationErrors.province" />
        </div>
        <div class="col-12 mb-4">
          <div class="form-group mb-0">
            <label class="form-control-label mt-0">{{
              $t("ESTIMATE_VEHICLES.MEMBER_SHIP")
            }}</label>
            <div class="d-flex justify-content-start align-items-center">
              <base-radio
                v-model="estimate.member_ship"
                v-for="option in memberShipOptions"
                :key="option"
                :value="option"
                :name="option"
                inline
              >
                {{ $t(`COMMON.${option}`) }}
              </base-radio>
            </div>
          </div>

          <validation-error :errors="apiValidationErrors.member_ship" />
        </div>
        <div
          class="col-12 mb-4"
          v-if="
            $currentUserCan(
              $permissions.PERM_VIEW_VEHICLE_ESTIMATIONS_DEBUG_MODE
            )
          "
        >
          <base-checkbox
            :checked="estimate.debug_mode"
            :disabled="readOnly"
            @input="
              (check) => {
                estimate.debug_mode = check;
              }
            "
          >
            {{ $t("COMMON.ENABLE_DEBUG_MODE") }}
          </base-checkbox>
        </div>
      </div>

      <div class="d-flex">
        <div class="advanced-search-item update">
          <base-button
            type="button"
            native-type="submit"
            class="add"
            icon
            size="sm"
            v-if="!readOnly"
            :disabled="loading"
          >
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            <span class="btn-inner--text text-white">
              {{ $t("ESTIMATE_VEHICLES.VEHICLE_ESTIMATE_BTN") }}
            </span>
          </base-button>
        </div>
        <div class="advanced-search-item update">
          <base-button
            type="button"
            class="add"
            icon
            size="sm"
            v-if="!readOnly"
            :disabled="loading"
            @click.prevent="resetForm"
          >
            <span class="btn-inner--text text-white">
              {{ $t("COMMON.RESET") }}
            </span>
          </base-button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import { cloneDeep } from "lodash";
import ValidationError from "@/components/ValidationError.vue";
import { ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import MakeSelector from "@/components/MakeSelector.vue";
import YearSelector from "@/components/YearSelector.vue";
import ModelSelector from "@/components/ModelSelector.vue";
import SerieSelector from "@/components/SerieSelector.vue";
import StyleSelector from "@/components/StyleSelector.vue";
import ProvinceSelector from "@/components/ProvinceSelector.vue";
import { Option, Select } from "element-ui";
import {
  estimateVehicleConditionsStatesOption,
  estimateVehicleMemberShipOption,
} from "@/constants/estimateVehicles";

export default {
  layout: "DashboardLayout",

  components: {
    ValidationError,
    ValidationObserver,
    MakeSelector,
    YearSelector,
    ModelSelector,
    SerieSelector,
    StyleSelector,
    ProvinceSelector,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: {
    estimateData: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    formErrors: {
      type: Array | Object | null | undefined,
      default: null,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const estimate = { ...this.estimateData };
    return {
      estimate,
      statesOptions: estimateVehicleConditionsStatesOption,
      memberShipOptions: estimateVehicleMemberShipOption,
    };
  },

  created() {
    // this.changeRequest(this.estimate);
    extend("required", {
      required,
      message: (_) => this.$t("validations.messages.required"),
    });
  },

  methods: {
    async hdlSubmit() {
      const estimateData = { ...this.estimate, series: this.estimate.serie };

      this.$emit("formChanged");
      this.$emit("estimateSubmitted", estimateData);
    },

    onFormChanged() {
      this.$emit("formChanged");
    },

    resetForm() {
      this.estimate = { ...this.estimateData };
      this.$emit("onResetForm");
    },
  },

  watch: {
    formErrors(errors) {
      const errs = errors ?? [];
      const errorsRefactor = errs.map((error) => {
        let err = { ...error };
        if (error?.source?.pointer === "/data") {
          if (error.detail.includes("make")) {
            err = {
              ...err,
              source: {
                pointer: "/data/attribute/make",
              },
            };
          }
          if (error.detail.includes("model")) {
            err = {
              ...err,
              source: {
                pointer: "/data/attribute/model",
              },
            };
          }
          if (
            error.detail.includes("year") ||
            error.detail.includes("annee") ||
            error.detail.includes("année")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/attribute/year",
              },
            };
          }
          if (error.detail.includes("kms")) {
            err = {
              ...err,
              source: {
                pointer: "/data/attribute/kms",
              },
            };
          }
          if (error.detail.includes("style")) {
            err = {
              ...err,
              source: {
                pointer: "/data/attribute/style",
              },
            };
          }
          if (
            error.detail.includes("serie") ||
            error.detail.includes("series")
          ) {
            err = {
              ...err,
              source: {
                pointer: "/data/attribute/serie",
              },
            };
          }
          if (error.detail.includes("condition")) {
            err = {
              ...err,
              source: {
                pointer: "/data/attribute/condition",
              },
            };
          }
        }
        return err;
      });
      this.setApiValidation(errorsRefactor);
    },
    estimateData(estimateData) {
      if (estimateData) {
        this.estimate = {
          ...this.estimate,
          ...cloneDeep(estimateData),
        };
      }
    },
  },
};
</script>
